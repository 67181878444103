body {
  font-family: 'Roboto Condensed', Roboto, sans-serif;
  color: #3c3c3c;
  margin: 0;
}
button {
  font-family: 'Roboto Condensed', Roboto, sans-serif;
}
h2 {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 10px;
}
.motor {
  display: flex;
}
.motor div {
  padding: 10px;
}
.motor button {
  min-width: 100px;
}
.motors {
  background-color: rgba(234, 234, 234, 0.4);
  padding-bottom: 10px;
}
.motor-info {
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
}
.motor-heading {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 10px; */
}
.motor-label {
  font-size: 16px;
}
.motor-degrees {
  color: #3AA970;
  font-size: 16px;
}
.motor-control {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.motor-button {
  min-width: 28px;
  height: 40px;
  background: none;
  border: none;
}
.motor-button.plus {
  background-image: url('images/plus_enabled.svg');
}
.motor-button.plus:disabled {
  background-image: url('images/plus_disabled.svg');
}
.motor-button.minus {
  background-image: url('images/minus_enabled.svg');
}
.motor-button.minus:disabled {
  background-image: url('images/minus_disabled.svg');
}
.degrees {
  display: flex;
  flex-flow: nowrap;
}
.degree {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
  width: 18px;
}
.degree label {
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  text-align: right;
}

.degree b {
  height: 2px;
  border-color: #3c3c3c;
  border-style: none;
  border-right-style: solid;
  border-right-width: 1px;
}
.degree b.major {
  height: 4px;
}
.ruler {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  overflow-x: hidden;
  background: #fff;
  flex-grow: 1;
  margin-left: 2px;
  margin-right: 2px;
  /* margin-left: 2px;
  margin-right: 2px; */
  border-radius: 20px;
}

.gripper-panel {
  margin: 20px;
  background-color:rgba(234, 234, 234, 0.4);
  border-radius: 5px;
}
.motor-info#motor-gripper {
  margin: 10px;
  
}
.gripper-control {
  margin-top: 10px;
  margin-left: 10px;
  padding-bottom: 10px;
}
.gripper-button {
  border-radius: 5px;
  background-color: #fff;
  width: 48px;
  height: 48px;
  border: none;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-position: center;
}
.gripper-button.active {
  background-color: #FECB2F;
}
.gripper-button:disabled {
  background-color: #A9A9A9;
}
.gripper-button.open {
  background-image: url('/images/gripper_open.svg');
}
.gripper-button.close {
  background-image: url('/images/gripper_closed.svg');
}
.submit-button {
  border: none;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  height: 43px;
  color: #3c3c3c;
  border-radius: 6px;
  width: 119px;
  background-color: #FECB2F;
}
.submit-button:disabled {
  background-color: #A9A9A9;
}

.middle {
  display: flex;
  justify-content: center;
}
.dashboard {
  display: flex;
  margin-left: 20px;
}

.status {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: start;
}
.robot-illustration {
  overflow-x: hidden;
  flex-grow: 1;
  display: flex;
  align-items: end;
  justify-content: end;

}
.robot-illustration-image {
  /* height: 200px; */
  height: 100%;
  width: 100%;
  background-image: url('/images/robot_illustration.svg');
  background-repeat: no-repeat;
  background-position: right;
}
.status div {
  display: flex;
  flex-direction: column;
}
.moves-counter {
  font-size: 40px;
  font-weight: 600;
}
.status-subtitle {
  color: #808285;
  font-size: 16px;
  font-weight: 500;
}
.connection {
  /* width: 90px; */
  align-items: center;
  margin-bottom: 20px;
}
.pointers div {
  position: absolute;
  right: calc(50% - 4px);
}
.degree-pointer {
  z-index: 2;
}
.goal-pointer {
  z-index: 1;
}
.pointers {
  height: 7px;
  width: 100%;
}

.queue-container {
  height: max-content;
  
}
.container.dark {
  min-height: 100vh;
  background-color: #28293A;
  justify-content:start;
  color:rgba(255, 255, 255, 0.8)
}

.content {
  margin: 20px;
  margin-bottom: 0px;
  margin-top: 5px;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}
h3 {
  width: 100%;
  display: flex;
  color: #fff;
  font-size: 26px;
}
.header-block {
  background-color: #FFCC33;
  width: 6px;
  height: 28px;
  margin-right: 6px;
}
.subtitle {
  width: 100%;
  text-align: left;
  color: #FFCC33;
  font-size: 26px;
}
.cta {
  text-align: center;
}
.socials {
  display: flex;
  margin-top: 10px;
}
.socials.share a {
  margin: 20px;
}
.socials a {
  margin: 20px;
  width: 30px;
}
.success-text {
  font-size: 26px;
  margin-top: 20px
}
.success-cta {
  font-size: 24px;
  margin-top: 5px;
  font-weight: bold;
  color: #ffcc33;
}
.bottom-aligned {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
}
.session-number {
  font-size: 8em;
  color: #fff;
}
.connection-image-good, .connection-image-bad {
  margin-bottom: 10px;
}
.badge {
  margin-top: 10px;
  margin-bottom: 10px; 
}
.site-link {
  color:#FFCC33;
  font-size: 24px;

}